import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  phone: "",
  message: "",
  showMessage: false, // State to manage success message display
};

export const Contact = (props) => {
  const [{ name, email, phone, message, showMessage }, setState] = useState(
    initialState
  );

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Only allow digits and dashes in the phone input
    if (name === "phone" && !/^\d{0,3}-?\d{0,3}-?\d{0,4}$/.test(value)) {
      return; // Do not update state if input format is incorrect
    }

    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const formatPhoneNumber = (phoneNumber) => {
    // Basic formatting example: xxx-xxx-xxxx
    const cleaned = (`${phoneNumber}`).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      let formattedNumber = '';
      if (match[1]) formattedNumber += match[1];
      if (match[2]) formattedNumber += (formattedNumber ? '-' : '') + match[2];
      if (match[3]) formattedNumber += (formattedNumber ? '-' : '') + match[3];
      return formattedNumber;
    }
    return phoneNumber;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate phone number format
    if (!/^(\d{3}-\d{3}-\d{4})$/.test(phone)) {
      alert("Please enter a valid phone number in xxx-xxx-xxxx format.");
      return;
    }

    emailjs
      .sendForm(
        "YOUR_SERVICE_ID",
        "YOUR_TEMPLATE_ID",
        e.target,
        "YOUR_USER_ID" // Replace with your User ID from EmailJS dashboard
      )
      .then(
        (result) => {
          console.log(result.text);
          clearState(); // Reset form fields
          setState((prevState) => ({ ...prevState, showMessage: true })); // Show success message
          setTimeout(() => {
            setState((prevState) => ({ ...prevState, showMessage: false })); // Hide success message after 3 seconds
          }, 3000);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      {showMessage && (
        <div className="alert alert-success" role="alert">
          Message submitted successfully!
        </div>
      )}
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        value={name}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        value={email}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Phone (xxx-xxx-xxxx)"
                        maxLength="12" // Limit input to maximum characters for format
                        required
                        value={formatPhoneNumber(phone)}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    value={message}
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2023 Issaaf Kattan React Land Page Template. Design by{" "}
            <a href="http://www.templatewire.com" rel="nofollow">
              TemplateWire
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
